@media screen and (min-width: 768px) {
  .mobile-navbar {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .mobile-navbar {
    padding: 5px 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;

    .login {
      cursor: pointer;

      padding: 4px 14px;
      border-radius: 6px;

      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;

      color: #175c83;
      border: 2px solid #175c83;
    }
  }

  .mobile-Drawer-navbar {
    background-color: #175c83;
    position: sticky;
    z-index: 1;
    top: 0;
    width: 100%;

    .login {
      cursor: pointer;

      padding: 4px 14px;
      border-radius: 6px;

      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;

      color: #fff;
      border: 2px solid #fff;
    }
  }
}

@media screen and (max-width: 767px) {
  .mobile-navbar {
    padding: 5px 10px;
  }
}
