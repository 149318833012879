.account-modal-component {
    .modal-content {
        border-radius: 20px;
        border: 2px solid #175c83;
    }
    
    .modal-dialog {
        max-width: 1000px;
        margin-top: 10vh;
    }

    .close-modal {
        display: flex;
        justify-content: flex-end;
        padding: 15px 20px;

        .icon {
            cursor: pointer;
            font-size: 20px;
        }
    }

    .main-wrapper {
        margin-top: 10px;
        padding: 0 40px 40px;

        .tabs-wrapper {
            display: flex;
            align-items: center;
            gap: 16px;
      
            .tab {
              width: 100%;
      
              cursor: pointer;
      
              padding: 13px 10px;
      
              display: flex;
              align-items: center;
              justify-content: center;
      
              font-family: "Poppins";
              font-size: 16px;
              font-weight: 500;
              font-style: normal;
      
              text-align: center;
              color: rgba(0, 0, 0, 0.5);
              border-bottom: 4px solid #f5f5f5;
      
              .tab-active {
                color: #175c83;
                border-bottom: 4px solid #175c83;
                font-weight: 600;
              }
      
              span {
                font-family: "Poppins";
                font-size: 18px;
                font-weight: 400;
                color: #313133;
              }
      
              .close-project {
                font-size: 10px;
                margin-left: 20px;
                margin-right: 3px;
              }
      
              .icon {
                margin-left: 10px;
                font-size: 17px;
                transform: rotate(45deg);
              }
            }
      
            .tab-active {
              color: #175c83;
              border-bottom: 4px solid #175c83;
              font-weight: 600;
      
              span {
                font-weight: 900;
              }
            }
          }

        .tab-content-wrapper {
            padding: 20px;
            // border: 1px solid rgba(196, 196, 196, .25);
            // border-radius: 5px;
            // box-shadow: 0px 0px 20px rgb(0 0 0 / 10%);

            display: flex;

            .left {
                padding: 20px 20px;

                .profile {
                    width: 200px;
                    height: 260px;
                    border-radius: 50%;
                    overflow: hidden;
                    box-shadow: 0px 0px 20px rgb(0 0 0 / 10%);
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 100%;
                        object-fit: cover;
                        max-width: 100%;
                    }
                }
            }

            .right {
                width: 100%;
                padding-left: 40px;

                .table-data {
                    border-bottom: 1px solid #dfdfdf;

                    display: flex;
                    align-items: flex-start;
                    padding: 10px 0;

                    .heading {
                        font-family: "Poppins";
                        font-weight: 500;
                        font-size: 17px;
                        color: #000;
                        width: 50%
                    }

                    .text {
                        font-family: "Poppins";
                        font-weight: 400;
                        font-size: 17px;
                        color: #000;
                        overflow-wrap: break-word;
                        word-break: break-all;
                        color: #000;
                        width: 50%;
                    }
                }
            }

            .account-edit-buttons {
                margin: 26px 0 10px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;

                .button {
                    cursor: pointer;
                    background: #175c83 ;
                    border-radius: 16px;

                    font-family: "Poppins";
                    font-weight: 400;
                    font-size: 15px;
                    color: white;
                    text-align: center;
                    padding: 15px 40px;
                }
                .button:hover{
                    transition: .2s ease-in-out;
                    background-color: #175c83;
                    color: white;
                }
            }
        }
    }
}

@media screen and (max-width:1020px) {
    .account-modal-component {
        .modal-dialog {
            margin: 10vh 10px 5vh;
        }

        .main-wrapper {
            margin-top: 10px;
            padding: 0 20px 20px;

            .tab-content-wrapper {
                .left {
                    padding: 30px 40px;
                }

                .right {
                    padding-left: 20px;

                    .table-data {
                        flex-direction: column;

                        .heading {
                            font-size: 15px;
                            width: 100%
                        }

                        .text {
                            font-size: 15px;
                            width: 100%;
                        }
                    }
                }

                .account-edit-buttons {
                    margin: 30px 0 0;

                    .button {
                        font-size: 15px;
                        padding: 12px 30px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:767px) {
    .account-modal-component {
        .main-wrapper {
            padding: 0 10px 10px;

            .tabs-wrapper {
                padding: 5px 0 0 1px;
                overflow-x: scroll;

                .tab {
                    min-width: 150px;
                    height: 45px;

                    span {
                        font-size: 15px;

                    }
                }
            }

            .tab-content-wrapper {
                padding: 20px 20px 0;
                display: flex;
                flex-direction: column;

                .left {
                    padding: 20px;

                    .profile {
                        width: 140px;
                        height: 170px;
                    }
                }

                .right {
                    padding-left: 0px;

                    .account-edit-buttons {
                        margin: 10px 0 20px;

                        .button {
                            margin-top: 20px;
                            padding: 10px 20px;
                        }

                        .button:first-child {
                            margin-right: 20px;
                        }
                    }
                }

            }
        }
    }
}